body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'kelson_sans';
  src: url('assets/kelson_sans_bold-webfont.woff2') format('woff2'),
  url('assets/kelson_sans_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'kelson_sans_light';
  src: url('assets/kelson_sans_light-webfont.woff2') format('woff2'),
  url('assets/kelson_sans_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'kelson_sans';
  src: url('assets/kelson_sans_regular-webfont.woff2') format('woff2'),
  url('assets/kelson_sans_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html, body {
  font-family: 'kelson_sans', sans-serif;
}
.answer-button {
  border-style: solid;
  border-color: #E5004E;
  font-weight: bold;
}

.answer-button.right {
  border-width: 4px !important;
}

.answer-button.left {
  border-width: 4px !important;
}

  .answer-button:hover {
    background-color: #E5004E;
    border-color: #E5004E;
    color: #fff;
  }


@media only screen and (min-width: 768px) {
  .img-container-col {
    height: 100vh;
  }
  .img-container {
    position: fixed;
    width: 45vw;
    top: 100px;
  }

}

@media only screen and (max-width: 768px) {
  .img-container {
    position: relative;
    width: auto;
    top: 0;
    height: 50vh;
  }

  .answer-button.previous {
    margin-bottom: 20px;
  }
}


.img-container img {
  position: absolute;
  width: 90%;
}
